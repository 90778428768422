import { db, resultsCollectionRef } from "@/@core/firebase"
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  Timestamp,
} from "firebase/firestore"

export type ReportPerDay = {
  green: number[][]
  loss: number[][]
  hit: number[][]
}

export type ReportPerDayResponse = {
  name: string
  type?: string
  data: number[][]
}[]

const useReport = (game: "double" | "crash", envId: string) => {
  const getEmptyDates = (
    count: number,
    randon: boolean = false
  ): number[][] => {
    const initialDate = (value: any, index: number) => {
      const dt = new Date()
      dt.setDate(dt.getDate() - (count - index - 1))
      return [dt.getTime(), randon ? Math.round(Math.random() * 100) : 0]
    }

    return Array(count).fill([]).map(initialDate)
  }

  const getReportPerDay = async (
    count: number
  ): Promise<ReportPerDayResponse> => {
    const reportsCollection = collection(
      db,
      "blaze",
      game,
      "environments",
      envId,
      "reports"
    )

    const daysQuery = query(
      reportsCollection,
      orderBy("updatedAt", "desc"),
      limit(count)
    )
    const daysDocs = await getDocs(daysQuery)

    const result = daysDocs.docs.reduce<ReportPerDay>(
      (acc: ReportPerDay, doc, index) => {
        const time: Timestamp = doc.data().updatedAt
        const dateTime = time.toDate()
        dateTime.setHours(0, 0, 0, 0)

        acc.green[count - index - 1] = [
          dateTime.getTime(),
          +doc.data().greenCount,
        ]
        acc.loss[count - index - 1] = [
          dateTime.getTime(),
          +doc.data().lossCount,
        ]
        acc.hit[count - index - 1] = [
          dateTime.getTime(),
          Math.round(
            (+doc.data().greenCount * 100) /
              (+doc.data().lossCount + +doc.data().greenCount)
          ),
        ]

        return {
          green: acc.green,
          loss: acc.loss,
          hit: acc.hit,
        } as ReportPerDay
      },
      {
        green: getEmptyDates(count),
        loss: getEmptyDates(count),
        hit: getEmptyDates(count),
      } as ReportPerDay
    )
    return [
      {
        name: "Green",
        data: result.green,
      },
      {
        name: "Loss",
        data: result.loss,
      },
      {
        name: "Acerto",
        type: "area",
        data: result.hit,
      },
    ]
  }

  return {
    getReportPerDay,
    getEmptyDates,
  }
}

export default useReport
